body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ReactCrop__crop-selection{
  border: none ! important;
  box-shadow: none !important;

}

.ReactCrop.ReactCrop--active .ReactCrop__crop-selection {
  border: 1px dashed #fff ! important;

}

.ReactCrop__selection-addon {
margin-left: -30px;
  margin-right: -50px;
}